<template>
    <div class="login-box" style="margin-left: auto;margin-right: auto;">
        <!-- /.login-logo -->
        <form method="post">
            <div class="login-logo mb-5">
                <a href="../../index2.html"><img height="64" src="@/assets/img/logo-white.png"></a>
            </div>
            <div class="card mb-0">
                <div class="card-body login-card-body">
                    <div>
                        <a href=""><img width="320" src="@/assets/img/Process-pana.png"></a>
                    </div>
                    <legend style="text-align: center;">Pembayaran kamu sedang diperiksa</legend>
                    <span v-on:click="toHome" class="btn btn-outline btn-lg btn-secondary mr-3" style="display: block;"><i class="fas fa-arrow-left"></i> Kembali </span>
                    <div class="text-center mt-5 mb-5">
                        <p>All Right Reserved</p>
                        <p>TARUNA CENDEKIA © 2021</p>
                    </div>
                </div>
                <!-- /.login-card-body -->
            </div>
        </form>
    </div>
    <!-- /.login-box -->
</template>
<script>
import { auth } from '@/libs/hxcore';
import $ from 'jquery';
//import '@/assets/login.css';
export default {
    name: 'PendingRegistration',
    data() {
        return {
            errors: [],
            loading: false,
            failed: false,
            failedMsg: '',
            form: {
                username: '',
                password: ''
            }
        }
    },
    created: function() {
        $('body').addClass('hold-transition login-page');
        $('body').removeAttr('style');
    },
    methods: {
        toHome: function(){
            window.location.href = '/';
        }
    }
}
$(function() {
});
</script>
<style lang="scss">
.login-page,
.register-page {
    -ms-flex-align: center;
    align-items: center;
    background: linear-gradient(90deg, #6190E8 0%, #A7BFE8 100%);
    @media (max-width: 576px){
        .login-box, .register-box {
        margin-top: 20px;
        width: 360px; 
    }
}

    .login-box {
        height: 100vh;
        position: relative;

        .form-control {
            height: calc(2rem + 2px);
            border:0;
        }

        .card {

            border-top-left-radius: 20px !important;
            border-top-right-radius: 20px !important;
        }

        .login-card-body,
        .register-card-body {
            border-top-left-radius: 20px !important;
            border-top-right-radius: 20px !important;
            background-color: #F1F1FA;
            .input-group .input-group-text
            {
                background-color:#fff;
                border:0;
            }
        }

        form {
            bottom: 0;
            position: absolute;
            width: 100%;
        }
    }
}
</style>
